import { underscore } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

import { adapterAction, apiAction } from '@mainmatter/ember-api-actions';
import { variation } from 'ember-launch-darkly';
import { isNil } from 'es-toolkit';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { supplierInvoiceNamespace } from 'qonto/constants/hosts';
import transformKeys from 'qonto/utils/transform-keys';

export default class SupplierInvoiceAdapter extends JSONAPIAdapter {
  namespace = supplierInvoiceNamespace;

  @waitFor
  async updateRecord(store, type, snapshot) {
    let data = { supplier_invoice: this.serialize(snapshot, { includeId: true }) };

    if (snapshot.adapterOptions?.partial) {
      let updates = Object.keys(snapshot.changedAttributes()).reduce((record, key) => {
        let underscored = underscore(key);
        record[underscored] = data.supplier_invoice[underscored];
        return record;
      }, {});

      data = { supplier_invoice: { ...updates, id: snapshot.id } };
    }

    // German e-invoices need to be allowed to be updated when due_date is not available
    let { due_date, is_einvoice, total_amount, is_credit_note } = data.supplier_invoice;
    if (is_einvoice && isNil(due_date) && Number(total_amount.value) > 0) {
      delete data.supplier_invoice.due_date;
    }

    // due_date is not required for a credit note
    if (variation('feature--boolean-ap-credit-notes') && is_credit_note && isNil(due_date)) {
      delete data.supplier_invoice.due_date;
    }

    return await apiAction(snapshot.record, { method: 'PATCH', data });
  }

  @waitFor
  async export({ dateFrom, dateTo, format, groupByStatuses }) {
    await adapterAction(this, 'supplier-invoices', {
      method: 'POST',
      path: 'export',
      data: {
        date_from: dateFrom,
        date_to: dateTo,
        format,
        group_by_statuses: groupByStatuses,
      },
    });
  }

  @waitFor
  async assignApprovalWorkflow({ id, action, declinedNote }) {
    let response = await adapterAction(this, 'supplier-invoices', {
      method: 'POST',
      path: `${id}/assign_approval_workflow`,
      data: {
        action,
        declined_note: declinedNote,
      },
    });

    return transformKeys(response);
  }

  @waitFor
  async reject({ id, declinedNote }) {
    let response = await adapterAction(this, 'supplier-invoices', {
      method: 'POST',
      path: `${id}/reject`,
      data: {
        declined_note: declinedNote,
      },
    });

    return transformKeys(response);
  }
}
