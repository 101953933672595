import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class CashFlowAbility extends ApplicationAbility {
  get canView() {
    return this.featuresManager.isEnabled('displayCashflowV1') && this.permissions.cash_flow.view;
  }

  get canNavigate() {
    return this.canView;
  }

  get canEditForecast() {
    return this.permissions.cash_flow.edit_forecast;
  }

  get canViewSidepanelTransactions() {
    return (
      variation('feature--boolean-cash-flow-sidepanel-slice-1') &&
      this.permissions.transactions.read
    );
  }

  get canViewUpcomingTransactions() {
    return (
      this.permissions.cash_flow.read_upcoming_transactions &&
      variation('feature--boolean-cash-flow-upcoming-transactions')
    );
  }
}
