import { variation } from 'ember-launch-darkly';

import { ORGA_STATUS } from 'qonto/constants/organization';

import ApplicationAbility from './application';

export default class CategoryAbility extends ApplicationAbility {
  get canAccess() {
    return (
      this.featuresManager.isEnabled('displayCashflowV1') &&
      variation('feature--boolean-cash-flow-categories-transactions') &&
      this.organization.status !== ORGA_STATUS.DEACTIVATED
    );
  }

  get canAssign() {
    return this.canAccess && this.permissions.cash_flow_categories.assign;
  }

  get canManage() {
    return this.canAccess && this.permissions.cash_flow_categories.manage;
  }
}
