import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class RequestAbility extends ApplicationAbility {
  @service userManager;
  @service subscriptionManager;

  get canNavigate() {
    let { requestTransfer, requestCard } = this.pricePlanFeatures;
    return (
      Boolean(requestTransfer || requestCard) &&
      !this.permissions.request_transfers.review &&
      !this.permissions.request_cards.review
    );
  }

  get canUse() {
    if (variation('feature--boolean-enable-reimbursements')) {
      let {
        requestTransfer,
        requestCard,
        requestReimbursementExpense,
        requestReimbursementMileage,
      } = this.pricePlanFeatures;
      return Boolean(
        requestTransfer || requestCard || requestReimbursementExpense || requestReimbursementMileage
      );
    } else {
      return Boolean(this.pricePlanFeatures.requests) || Boolean(this.pricePlanFeatures.requestsV3);
    }
  }

  get canCreate() {
    return Boolean(this.canCreateTransfer || this.canCreateCard || this.canCreateMileage);
  }

  get canReadEbics() {
    return (
      Boolean(this.pricePlanFeatures.connectIntegrationLevelSilver) &&
      this.canReviewTransfer &&
      !this.canUse
    );
  }

  get canRead() {
    return this.canUse || this.canReadEbics;
  }

  get canLoadTransfer() {
    return this.permissions.request_transfers.read;
  }

  get canReviewTransfer() {
    return this.permissions.request_transfers.review;
  }

  get canCreateTransfer() {
    let { pricePlanFeatures, permissions } = this;
    if (variation('feature--boolean-enable-reimbursements')) {
      let hasNeededPricePlans = pricePlanFeatures.requestTransfer;
      let cantCreateTransfer =
        !permissions.external_transfers.create && !permissions.internal_transfers.create;

      return Boolean(
        hasNeededPricePlans && permissions.request_transfers.create && cantCreateTransfer
      );
    } else {
      let hasNeededPricePlans = pricePlanFeatures.requests;
      let cantCreateTransfer =
        !permissions.external_transfers.create && !permissions.internal_transfers.create;

      return Boolean(
        hasNeededPricePlans && permissions.request_transfers.create && cantCreateTransfer
      );
    }
  }

  canCancelTransfer(initiatorUserId) {
    let currentUserId = this.userManager.currentUser.id;
    let isInitiator = currentUserId === initiatorUserId;
    return isInitiator && this.permissions.request_transfers.cancel && !this.canReviewTransfer;
  }

  get canReviewCard() {
    return this.permissions.request_cards.review;
  }

  get canCreateCard() {
    let { pricePlanFeatures, permissions } = this;
    if (variation('feature--boolean-enable-reimbursements')) {
      return Boolean(
        pricePlanFeatures.requestCard &&
          permissions.request_cards.create &&
          !permissions.cards.create
      );
    } else {
      return Boolean(
        pricePlanFeatures.requests &&
          pricePlanFeatures.requestsV3 &&
          permissions.request_cards.create &&
          !permissions.cards.create
      );
    }
  }

  get canCreateFlashCard() {
    return Boolean(this.pricePlanFeatures.flashCard);
  }

  canCancelCard(initiatorUserId) {
    let currentUserId = this.userManager.currentUser.id;
    let isInitiator = currentUserId === initiatorUserId;
    return isInitiator && this.permissions.request_cards.cancel && !this.canReviewCard;
  }

  get canUseExpenseReports() {
    if (variation('feature--boolean-enable-reimbursements')) {
      return Boolean(this.pricePlanFeatures.requestReimbursementExpense);
    } else {
      return this.canUse;
    }
  }

  get canCreateExpenseReport() {
    return this.permissions.request_expense_reports.create && this.canUseExpenseReports;
  }

  get canReviewExpenseReport() {
    return this.permissions.request_expense_reports.review && this.canUseExpenseReports;
  }

  canCancelExpenseReport(initiatorUserId) {
    let currentUserId = this.userManager.currentUser.id;
    let isInitiator = currentUserId === initiatorUserId;
    return (
      isInitiator && this.permissions.request_expense_reports.cancel && !this.canReviewExpenseReport
    );
  }

  get canUseMileages() {
    let { legalCountry } = this.organizationManager.organization;

    if (
      ['DE', 'ES', 'FR'].includes(legalCountry) &&
      // eslint-disable-next-line no-restricted-syntax
      this.subscriptionManager?.currentPricePlan?.groupCode !== 'solo_basic'
    ) {
      // Mileage reimbursement selector is shown even when requestReimbursementMileage is not enabled
      // so that we can upsell certain users
      return true;
    }

    return Boolean(this.pricePlanFeatures.requestReimbursementMileage);
  }

  get canUseMileageImprovements() {
    return this.canUseMileages;
  }

  get canCreateMileage() {
    return this.permissions.request_mileages.create && this.canUseMileages;
  }

  get canCreateMileageWithAddon() {
    return this.canCreateMileage && Boolean(this.pricePlanFeatures.requestReimbursementMileage);
  }

  get canReviewMileage() {
    return this.permissions.request_mileages.review && this.canUseMileages;
  }

  canCancelMileage(initiatorUserId) {
    let currentUserId = this.userManager.currentUser.id;
    let isInitiator = currentUserId === initiatorUserId;
    return isInitiator && this.permissions.request_mileages.cancel;
  }

  get canNavigateReimbursements() {
    return this.permissions.request_expense_reports.read || this.permissions.request_mileages.read;
  }

  get canUseReimbursements() {
    return this.canUseMileages || this.canUseExpenseReports;
  }

  get canNavigateReimbursementsApproverView() {
    return this.canReviewExpenseReport || this.canReviewMileage;
  }

  get canUseDirectDebitCollections() {
    if (variation('feature--boolean-enable-reimbursements')) {
      return Boolean(this.pricePlanFeatures.requestTransfer);
    } else {
      return Boolean(this.pricePlanFeatures.requests);
    }
  }

  get canReadDirectDebitCollection() {
    return (
      this.canUseDirectDebitCollections && this.permissions.request_direct_debit_collections.read
    );
  }

  get canReviewDirectDebitCollection() {
    return (
      this.canUseDirectDebitCollections && this.permissions.request_direct_debit_collections.review
    );
  }

  get canNavigateDirectDebitCollection() {
    return this.canReadDirectDebitCollection && this.canReviewDirectDebitCollection;
  }
}
